import { Topic } from 'api';
import { Text } from 'components/@tedui';
import { Link } from 'components/router';

interface TopicSlideProps {
  topic: Partial<Topic>;
  trackClick: (url: string) => void;
}

const TopicSlide = ({ topic, trackClick }: TopicSlideProps) => {
  const linkUrl = `/talks?sort=relevance&topics%5B0%5D=${topic.slug}`;

  return (
    <Link isZenithLink href={linkUrl} onClick={() => trackClick(topic.slug)}>
      <div className="rounded-sm bg-[rgba(255,255,255,0.3)] px-4 py-3">
        <Text
          variant="body2"
          tag="p"
          useNewTextStyles
          weight="font-semibold"
          UNSAFE_className="text-white text-center"
        >
          {topic.name}
        </Text>
      </div>
    </Link>
  );
};

export default TopicSlide;
