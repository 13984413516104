import { PhotoSize, SpotlightUnit, Video } from 'api';
import { Badge, Text } from 'components/@tedui';
import { UpdatedVideo } from 'components/PRISMIC/slices/FeaturedSpotlight';
import Image from 'components/image';
import { Link } from 'components/router';
import { formatDistanceToNowStrict } from 'date-fns';
import getImageFromSet from 'lib/get-image-from-set';
import { useOptimizedImage } from 'lib/hooks/useOptimizedImage';

import { useIntl } from 'react-intl';

import AnimatedIconButton from 'components/AnimatedIconButton';
import { getBadgeText } from 'components/talk-card/utils';
import mixpanel from 'lib/analytics/mixpanel';
import { useAuthenticated } from 'lib/auth';
import { ViewCount } from 'lib/business';
import type { LINK_TYPE } from '../../HeroSlider';

interface StandardCardProps {
  data: Partial<Video | SpotlightUnit | UpdatedVideo>;
  index: number;
  mixpanelTitle?: string;
  trackClick?: (linkType: LINK_TYPE, itemNumber: number) => void;
}

const StandardCard = ({ data, index, mixpanelTitle }: StandardCardProps) => {
  const { formatNumber } = useIntl();
  const loggedIn = useAuthenticated();

  const featuredImage =
    (data as UpdatedVideo).overrideImageSet?.url ??
    ((data as Video).primaryImageSet
      ? getImageFromSet((data as Video).primaryImageSet as PhotoSize[], '16x9')
      : (data as SpotlightUnit).imageUrl);

  const { src, blurredSrc } = useOptimizedImage({
    imageUrl: `${featuredImage}`,
    width: 1200,
    height: 675
  });

  const talkDuration = Math.floor((data as Video)?.duration / 60);

  const linkUrl = `/talks/${(data as Video).slug}`;

  const topic = (data as Video).topics?.nodes?.[0]?.name;
  const badgeText = data && getBadgeText((data as Video).type?.name || '');

  return (
    <div className="w-full">
      <Link
        href={`${linkUrl}`}
        isZenithLink
        onClick={() => {
          if (mixpanelTitle)
            mixpanel.track('homepage_click', {
              current_url: window.location.href,
              title: mixpanelTitle,
              item_number: String(index),
              link_type: 'image',
              value: ''
            });
        }}
      >
        <div className="relative">
          <div className="aspect-h-9 aspect-w-16">
            {src && (
              <Image
                alt={`${(data as Video).slug}`}
                src={src}
                placeholder="blur"
                blurDataURL={blurredSrc}
                className="object-cover object-top"
                fill
              />
            )}
          </div>

          {badgeText && (
            <div className={'absolute bottom-[0.6rem] left-2'}>
              <Badge
                text={badgeText.large}
                testId="badge--bottomLeft"
                isDarkBackground={false}
                backgroundColor="white"
                UNSAFE_className="!rounded-xs"
              />
            </div>
          )}

          <Text
            variant="unset"
            UNSAFE_className="text-tui-xs text-white absolute bottom-2 right-2 font-bold bg-textSecondary-onLight px-1 rounded-xs uppercase leading-[20.7px] -tracking-[0.72px]"
            tag="p"
          >
            {talkDuration} min
          </Text>
        </div>
      </Link>
      <div>
        <div className="mb-2 mt-3 flex items-center justify-between">
          <Link
            href={`/talks?sort=relevance&topics%5B0%5D=${topic}`}
            isZenithLink
            onClick={() => {
              if (mixpanelTitle)
                mixpanel.track('homepage_click', {
                  current_url: window.location.href,
                  title: mixpanelTitle,
                  item_number: String(index),
                  link_type: 'topic',
                  value: ''
                });
            }}
          >
            <div className="mb-2 mt-3">
              <Text
                variant="label1"
                tag="p"
                useNewTextStyles
                weight="font-semibold"
                color={{ override: '#EB0028' }}
              >
                {topic}
              </Text>
            </div>
          </Link>
          {loggedIn && (
            <AnimatedIconButton
              talk={data}
              itemNumber={String(index)}
              mixpanelTitle={mixpanelTitle}
            />
          )}
        </div>

        <Link
          href={`${linkUrl}`}
          isZenithLink
          onClick={() => {
            if (mixpanelTitle)
              mixpanel.track('homepage_click', {
                current_url: window.location.href,
                title: mixpanelTitle,
                item_number: String(index),
                link_type: 'title',
                value: ''
              });
          }}
        >
          <Text
            variant="subheader2"
            tag="p"
            weight="font-semibold"
            useNewTextStyles
          >
            {data.title}
          </Text>

          <div className="mt-2">
            <Text tag="p" variant="caption1">
              <>{(data as Video)?.presenterDisplayName} · </>
              <ViewCount count={(data as Video).viewedCount}>
                {formatNumber((data as Video)!.viewedCount, {
                  notation: 'compact',
                  compactDisplay: 'short'
                })}{' '}
                plays ·{' '}
              </ViewCount>
              {(data as Video)?.publishedAt &&
                formatDistanceToNowStrict(
                  new Date((data as Video)!.publishedAt)
                )}{' '}
              ago
            </Text>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default StandardCard;
