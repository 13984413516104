import { Text } from 'components/@tedui';
import { NavigationButton } from 'components/@tedui/Organisms/Ribbon/NavigationButton';
import { Link } from 'components/router';
import mixpanel from 'lib/analytics/mixpanel';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { useCallback, useEffect, useRef, useState } from 'react';
import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import TopicSlide from './TopicSlide';
import TOPIC_DATA from './topics_data.json';

SwiperCore.use([Navigation]);

interface TopicsLaunchPadHomepageProps {
  mixpanelTitle?: string;
}

const TopicsLaunchPadHomepage = ({
  mixpanelTitle
}: TopicsLaunchPadHomepageProps) => {
  const [mainSwiper, setMainSwiper] = useState<SwiperCore | null>(null);

  const [isSwiperEnd, setIsSwiperEnd] = useState(false);

  const navigationPrevRef = useRef<HTMLInputElement>(null);
  const navigationNextRef = useRef<HTMLInputElement>(null);

  const isMobileWidth = useIsBreakpointWidth({
    size: 'sm',
    breakPointType: 'tui'
  });

  const isMobileTabletWidth = useIsBreakpointWidth({
    size: 'lg',
    breakPointType: 'tui'
  });

  const trackClick = (topic: string) => {
    if (mixpanelTitle)
      mixpanel.track('homepage_click', {
        current_url: window.location.href,
        title: mixpanelTitle,
        item_number: '',
        link_type: 'button',
        value: topic
      });
  };

  const resetSwiper = useCallback((swiper: SwiperCore) => {
    setTimeout(() => {
      if (!swiper) return;
      // Re-init navigation
      swiper.navigation.destroy();
      swiper.navigation.init();
      swiper.navigation.update();
    }, 300);
  }, []);

  useEffect(() => {
    if (mainSwiper && navigationPrevRef.current && navigationNextRef.current) {
      //@ts-ignore
      mainSwiper.params.navigation.prevEl = navigationPrevRef.current;
      //@ts-ignore
      mainSwiper.params.navigation.nextEl = navigationNextRef.current;

      resetSwiper(mainSwiper);
    }
  }, [mainSwiper, isMobileTabletWidth]);

  return (
    <div className="w-full px-0 lg-tui:px-10 xl-tui:px-16">
      <div className="mx-auto w-full max-w-7xl bg-pureBlack sm:px-0">
        {isMobileTabletWidth && (
          <div className="flex items-center justify-between px-6 pt-10">
            <Text
              variant="header2"
              tag="p"
              weight="font-semibold"
              useNewTextStyles
              UNSAFE_className="text-white"
            >
              Discover
            </Text>
            <Link
              isZenithLink
              href={'/talks?sort=relevance'}
              onClick={() => {
                mixpanel.track('homepage_click', {
                  current_url: window.location.href,
                  title: mixpanelTitle,
                  item_number: '',
                  link_type: 'link',
                  value: ''
                });
              }}
            >
              <Text
                tag="p"
                variant="link1"
                weight="font-semibold"
                useNewTextStyles
                UNSAFE_className="text-white whitespace-nowrap underline"
              >
                See all
              </Text>
            </Link>
          </div>
        )}
        <div className="relative flex items-center justify-between px-5 pb-10 pt-8 lg-tui:pb-8">
          {!isMobileTabletWidth && (
            <Text
              variant="header3"
              tag="p"
              weight="font-semibold"
              useNewTextStyles
              UNSAFE_className="text-white"
            >
              Discover
            </Text>
          )}

          <div className="relative flex overflow-hidden px-0 lg-tui:px-6">
            {!isMobileWidth && !isSwiperEnd && (
              <div className="absolute -right-2 bottom-0 top-0 z-50 w-20 bg-gradient-to-r from-transparent to-pureBlack lg-tui:right-20" />
            )}

            {!isMobileTabletWidth && (
              <div className="z-50 flex items-center pr-6">
                <NavigationButton
                  direction="left"
                  navigationRef={navigationPrevRef}
                  UNSAFE_className="!w-8 !h-8 !relative !left-[initial] bg-opacity-50"
                  iconSize="large"
                />
              </div>
            )}
            <Swiper
              id="topics-slider"
              onSwiper={swiper => setMainSwiper(swiper)}
              onSlideChange={swiper => setIsSwiperEnd(swiper.isEnd)}
              onReachEnd={() => setIsSwiperEnd(true)}
              spaceBetween={16}
              style={{ maxWidth: 920 }}
              slidesPerView="auto"
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current
              }}
            >
              {TOPIC_DATA.topics.map(slide => {
                return (
                  <SwiperSlide
                    key={slide.name ?? slide.slug}
                    style={{ width: 'auto' }}
                  >
                    <TopicSlide topic={slide} trackClick={trackClick} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            {!isMobileTabletWidth && (
              <div className="z-50 flex items-center pl-6">
                <NavigationButton
                  direction="right"
                  navigationRef={navigationNextRef}
                  UNSAFE_className="!w-8 !h-8 !relative !right-[initial] bg-opacity-50"
                  iconSize="large"
                />
              </div>
            )}
          </div>
          {!isMobileTabletWidth && (
            <Link
              isZenithLink
              href={'/talks?sort=relevance'}
              onClick={() => {
                mixpanel.track('homepage_click', {
                  current_url: window.location.href,
                  title: mixpanelTitle,
                  item_number: '',
                  link_type: 'link',
                  value: ''
                });
              }}
            >
              <Text
                tag="p"
                variant="link1"
                weight="font-semibold"
                useNewTextStyles
                UNSAFE_className="text-white whitespace-nowrap underline"
              >
                See all
              </Text>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopicsLaunchPadHomepage;
